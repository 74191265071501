import {Login} from '../components/Login'
import {Home} from '../components/Home'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import {CecinasResumen} from '../components/CecinasResumen'
import {Seguimiento} from '../components/Seguimiento'
import {ResumenDeCataloge} from '../components/ResumenDeCataloge'
import {Vienesas} from '../components/Vienesas'
import {VienesasActivation} from '../components/VienesasActivation'
import {SeguimientoLinea} from '../components/SeguimientoLinea'
import {ResetPassword} from '../components/ResetPassword'
import {EmailSent} from '../components/EmailSent'
import {ResetPasswordFound} from '../components/ResetPasswordFound'
import {PasswordChangeSuccess} from '../components/PasswordChangeSuccess'
import {ConfiguracióndelPerfil} from '../components/ConfiguracióndelPerfil'
import {VienesasNew} from '../components/VienesasNew'
import {SeguimientoLineaNew} from '../components/SeguimientoLineaNew'
import {ResumenDeOferta} from '../components/ResumenDeOferta'
import {SeguimientoOferta} from '../components/SeguimientoOferta'
import {ProductSelection} from '../components/ProductSelection'
import {LoginNew} from '../components/LoginNew'
import {ForgetPasswordNew} from '../components/ForgetPasswordNew'
import {EmailCheck} from '../components/EmailCheck'
import {SetPasswordNew} from '../components/SetPasswordNew'
import {PasswordChangeDone} from '../components/PasswordChangeDone'
import {LinksShow} from '../components/LinksShow'
import HomePage from '../pages/HomePage/'
import {BusquedadePromociones, CataLogoResumen, Header} from '../componentsRef'
import LoginPage from '../pages/LoginPage'
import {PrivateRoute} from './PrivateRoute'
import {PublicRoute} from './PublicRoute'
import {IngresoPromocion} from '../componentsRef/IngresoPromocion'
import {Dashboard} from '../componentsRef/Dashboard'
import {IngresoPromocionResumen} from '../components/IngresoPromocionResumen'
import {Settings} from '../pages/user/Settings'
import {CatalogSearch} from '../pages/CatalogSearchPage'

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				{/* new work start */}

				<Route
					path='/'
					element={
						<PublicRoute>
							<LoginNew />
						</PublicRoute>
					}
				/>

				<Route path='/secciones_desarrollo' element={<LinksShow />} />



				<Route path='/catalogo/:idPromocion' element={
				<PrivateRoute>
					<IngresoPromocion />
				</PrivateRoute>
				} />

				<Route path='/dashboard/:pagina' element={
				<PrivateRoute>
					<Dashboard />
				</PrivateRoute>
				} />

				<Route
					path='/HomeNew'
					element={
						<PrivateRoute>
							<HomePage />
						</PrivateRoute>
					}
				/>

				<Route
					path='/CatalogSearch'
					element={
						<PrivateRoute>
							<CatalogSearch />
						</PrivateRoute>
					}
				></Route>

				<Route
					path='/LoginNew'
					element={
						<PublicRoute>
							<LoginNew />
						</PublicRoute>
					}
				/>

						

				{/* <Route path='/HomeNew'  element={<HomePage />} /> */}
				<Route path='/HomeRef' element={<LoginPage />} />

				{/* <Route path='/LoginNew' element={<LoginNew />} /> */}
				<Route path='/ProductSelection' element={<ProductSelection />} />
				{/* <Route path='/LoaderNew' element={<LoaderNew />} /> */}
				<Route path='/ForgetPasswordNew' element={<ForgetPasswordNew />} />
				<Route path='/EmailCheck' element={<EmailCheck />} />
				<Route path='/SetPasswordNew' element={<SetPasswordNew />} />
				<Route path='/PasswordChangeDone' element={<PasswordChangeDone />} />
				{/* new work end */}

				<Route path='/ResetPassword' element={<ResetPassword />} />
				<Route path='/ResetPasswordFound' element={<ResetPasswordFound />} />
				<Route path='/EmailSent' element={<EmailSent />} />
				<Route
					path='/PasswordChangeSuccess'
					element={<PasswordChangeSuccess />}
				/>
				<Route path='/Home' element={<Home />} />
				<Route path='/CataLogoResumen' element={<CataLogoResumen />} />
				<Route path='/Cecinas' element={<CecinasResumen />} />
				<Route path='/Seguimiento' element={<Seguimiento />} />
				<Route path='/SeguimientoOferta' element={<SeguimientoOferta />} />

				<Route path='/ResumenDeCataloge' element={<ResumenDeCataloge />} />

				{/* new work */}
				<Route path='/ResumenDeOferta' element={<ResumenDeOferta />} />

				<Route path='/Vienesas' element={<Vienesas />} />
				<Route path='/VienesasNew' element={<VienesasNew />} />

				<Route path='/VienesasActivation' element={<VienesasActivation />} />
				<Route path='/SeguimientoLinea' element={<SeguimientoLinea />} />
				<Route path='/SeguimientoLineaNew' element={<SeguimientoLineaNew />} />

				<Route
					path='/BusquedadePromociones'
					element={<BusquedadePromociones />}
				/>
				<Route
					path='/ConfiguraciondelPerfil'
					element={<ConfiguracióndelPerfil />}
				/>
				<Route path='/IngresoPromocion' element={<IngresoPromocion />} />
				<Route
					path='/IngresoPromocionResumen'
					element={<IngresoPromocionResumen />}
				/>
				<Route path='/Settings' element={<Settings />} />
				{/* <Route path='/CatalogSearch' element={<CatalogSearch />} /> */}
			</Routes>
		</BrowserRouter>
	)
}
