import React, { useRef , useState, useEffect} from 'react'
import { config } from '../../config';
import { LoaderNew } from '../../components/LoaderNew';
import { envVariables } from '../../utils/envVariables/envVariables';

export const CargaMasiva = ({setCargaMasiva, typePromotion, idPromocion, promotion}) => {
	const [statusButton, setStatusButton] = useState("Cargar Archivo")
	const [loader, setLoader] = useState(false)
	const [erroresValidacion, setErroresValidacion] = useState(null)
	const fileInput = useRef(null);

	console.log("INFO DE PROMOTION ")
	console.log(promotion)

	const confirmarEnvio = () => {
		const myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
		fetch(envVariables.baseUrl+'/promotion/bulk/create', {
			method: 'POST',
			headers: myHeaders,
		})
		.then(response => {
			console.log(response)
			window.location.reload()
		})
		.catch(error => {
			console.error('Error:', error);
			alert("Error "+error)
			setStatusButton("Confirmar Envío")
			setLoader(false)
		});
		setLoader(false)
	}

	const enviarArchivo = () => {
        const file = fileInput.current.files[0];
		const myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
        const formData = new FormData();
        formData.append('file', file);
		formData.append('tipo_promocion', typePromotion);
		formData.append("nombre_cadena", String(promotion.data_promocion.nombre_cadena).toUpperCase());
		formData.append("id_promocion", idPromocion);
        fetch(envVariables.baseUrl+'/promotion/bulk/validate', {
            method: 'POST',
			headers: myHeaders,
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error en la solicitud');
            }
            return response.json();
        })
        .then(data => {
            // Manejar la respuesta del servicio si es necesario
            console.log('Respuesta del servicio:', data);
			if(data.error_messages){
				if(data.error_messages.length > 0){
					let errores = ""
					let countErrores = 0
					data.error_messages.map((error) => {
						if(error.total_errores > 0){
							countErrores += 1
						}
						errores += "- ERROR: "+error.codigo_producto+" con "+error.total_errores+" errores \n"
						return error
					})
					if(countErrores === 0){
						errores = "Todas las filas están correctas, ¿Desea confirmar el envío?"
						setStatusButton("Confirmar Envío")
					}else{
						setStatusButton("Cargar Archivo")
					}
					// document.getElementById("erroresValidacion").innerHTML = "Errores de validación: " + errores
					setErroresValidacion(errores)
					
				}else{
					setErroresValidacion(null)
					const validacion = "Todas las filas están correctas, ¿Desea confirmar el envío?"
					setErroresValidacion(validacion)
					setStatusButton("Confirmar Envío")
				}
			}
			setLoader(false)
        })
        .catch(error => {
            console.error('Error:', error);
			alert("Error "+error)
			setLoader(false)
			setErroresValidacion("Error en la carga masiva, intente más tarde.")
			setStatusButton("Cargar Archivo")
        });
		setLoader(false)
    };

	return (
		<>
			<span
				className='black_bg popup'
				onClick={(e) => {
					setCargaMasiva(null)
				}}
			></span>
			<div className='LogoutPopupNew InicioPop'>
				<svg
					width='44'
					height='44'
					viewBox='0 0 44 44'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect width='44' height='44' rx='22' fill='#F0F4FC' />
					<mask
						id='mask0_280_410497'
						maskUnits='userSpaceOnUse'
						x='6'
						y='6'
						width='32'
						height='32'
					>
						<rect x='6' y='6' width='32' height='32' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_280_410497)'>
						<path
							d='M9.73398 34.1332C9.13398 34.1332 8.69487 33.8835 8.41665 33.3839C8.13931 32.8835 8.14509 32.3777 8.43398 31.8666L20.6673 10.6666C20.9562 10.1555 21.4006 9.8999 22.0006 9.8999C22.6006 9.8999 23.0451 10.1555 23.334 10.6666L35.5673 31.8666C35.8562 32.3777 35.862 32.8835 35.5846 33.3839C35.3064 33.8835 34.8673 34.1332 34.2673 34.1332H9.73398ZM22.0006 19.4332C21.6229 19.4332 21.3064 19.5608 21.0513 19.8159C20.7953 20.0719 20.6673 20.3888 20.6673 20.7666V24.6666C20.6673 25.0443 20.7953 25.3608 21.0513 25.6159C21.3064 25.8719 21.6229 25.9999 22.0006 25.9999C22.3784 25.9999 22.6953 25.8719 22.9513 25.6159C23.2064 25.3608 23.334 25.0443 23.334 24.6666V20.7666C23.334 20.3888 23.2064 20.0719 22.9513 19.8159C22.6953 19.5608 22.3784 19.4332 22.0006 19.4332ZM22.0006 29.9666C22.3784 29.9666 22.7006 29.8332 22.9673 29.5666C23.234 29.2999 23.3673 28.9777 23.3673 28.5999C23.3673 28.2221 23.234 27.8999 22.9673 27.6332C22.7006 27.3666 22.3784 27.2332 22.0006 27.2332C21.6229 27.2332 21.3006 27.3666 21.034 27.6332C20.7673 27.8999 20.634 28.2221 20.634 28.5999C20.634 28.9777 20.7673 29.2999 21.034 29.5666C21.3006 29.8332 21.6229 29.9666 22.0006 29.9666ZM12.134 31.2332H31.8673L22.0006 14.2332L12.134 31.2332Z'
							fill='#3676F5'
						/>
					</g>
				</svg>

				<h1>Carga Masiva tipo: {typePromotion}</h1>
				<p id={"erroresValidacion"}>Debes utilizar el siguiente formato para realizar la carga masiva:
                <input type='file' id='file' name='file' accept='.csv, .xlsx, .xls' ref={fileInput}/>
				{loader && <LoaderNew notdo={true}/>}
				{erroresValidacion && erroresValidacion}
                </p>

                

				<div className='button_wrapper'>
					<button
						onClick={(e) => {
							if(statusButton !== 'Confirmar Envío'){
								enviarArchivo()
								// setCargaMasiva(null)
								setStatusButton("Cargando...")
								setLoader(true)
							}else{
								confirmarEnvio()
								setStatusButton("Enviando...")
								setLoader(true)
							}
							
						}}
					>
						{statusButton}
					</button>
				</div>

				<svg
					onClick={(e) => setCargaMasiva(false)}
					width='10'
					height='10'
					viewBox='0 0 10 10'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M4.99994 6.06666L1.79993 9.26666C1.65549 9.41111 1.47771 9.48333 1.2666 9.48333C1.05549 9.48333 0.877713 9.41111 0.733268 9.26666C0.588824 9.12222 0.516602 8.94444 0.516602 8.73333C0.516602 8.52222 0.588824 8.34444 0.733268 8.2L3.93327 5L0.733268 1.8C0.588824 1.65555 0.516602 1.47777 0.516602 1.26666C0.516602 1.05555 0.588824 0.877774 0.733268 0.733329C0.877713 0.588885 1.05549 0.516663 1.2666 0.516663C1.47771 0.516663 1.65549 0.588885 1.79993 0.733329L4.99994 3.93333L8.19993 0.733329C8.34438 0.588885 8.52216 0.516663 8.73327 0.516663C8.94438 0.516663 9.12216 0.588885 9.2666 0.733329C9.41105 0.877774 9.48327 1.05555 9.48327 1.26666C9.48327 1.47777 9.41105 1.65555 9.2666 1.8L6.0666 5L9.2666 8.2C9.41105 8.34444 9.48327 8.52222 9.48327 8.73333C9.48327 8.94444 9.41105 9.12222 9.2666 9.26666C9.12216 9.41111 8.94438 9.48333 8.73327 9.48333C8.52216 9.48333 8.34438 9.41111 8.19993 9.26666L4.99994 6.06666Z'
						fill='#ADADAD'
					/>
				</svg>
			</div>
		</>
	)
}
