import React, { useState, useEffect, ReactDOM, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'

import { EliminarShow } from './EliminarShow'
import { Mover } from './Mover'
import {envVariables} from '../../utils/envVariables/envVariables'

import bookmark from '../../assets/img/bookmark.svg'
import AvatarPop from '../../assets/img/AvatarPop.svg'
import Actividad from '../../assets/img/Actividad.svg'
import Badge from 'react-bootstrap/Badge'
import Resumen from '../../assets/img/Icono Resumen.svg'
import Cerrar from '../../assets/img/Cerrar.svg'
import Avatargirl from '../../assets/img/Avatargirl.svg'
import {getGrocersImg} from '../CreateCatalog/helpers'


//  import ReactDOMServer from 'react-dom/server';

function getComments(promotionId) {
	const sessionToken = localStorage.getItem('token')
	// console.log(sessionToken);
	// if there is a session token stored
	if (sessionToken) {
		return fetch(
			envVariables.baseUrl + `/actividad/` + promotionId,
			{
				// mode: 'no-cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + sessionToken,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
			}
		)
			.then((response) => {
				// console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				// console.log(response)
				return response
			})
	}
	return {
		// return empty json
	}
}

export const TarjetaPF = ({ setActive, Activityactive , catalogSelected}) => {
	moment.locale('es')
	const navigate = useNavigate()
	const [color, setColor] = useState('')
	const [eliminarShow, seteliminarShow] = useState(false)
	const [moverShow, setmoverShow] = useState(false)
	const [dataComments, setDataComments] = useState(false)
	const [flag, setFlag] = useState(true)

	console.log("catalogo seleccionado tarjeta :::: ");
	console.log(catalogSelected);

	const refCatalogModal = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeModal, true)
	}, [])

	const closeModal = (e) => {
		if (!refCatalogModal.current.contains(e.target)) {
			setActive(false)
		}
	}
	
	useEffect(() => {
		// setData({promotions: window.catalog_categories})
		
		getComments(catalogSelected?.id_promo).then((result) => {
			console.log('##########comentarios:::: ')
			console.log(result)
			setDataComments(result)
		})
		setFlag(false)
		
	}, [flag])
	
	const Callback = async () => {
		const comment = document.getElementById('input_comment').value
		const sessionToken = localStorage.getItem('token')
		if (sessionToken) {
			const response = await fetch(envVariables.baseUrl + `/comment/create`, {
				// mode: 'no-cors',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + sessionToken,
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
				},
				body: JSON.stringify(
					{
						id_promocion: catalogSelected?.id_promo,
						comentario: comment,
					},
				),
			}).then((response) => {
				return response
			}).catch((error) => {
				console.log(error)
				alert("Error al crear el comentario");
				document.getElementById('input_comment').value = ''
				document.getElementById('input_comment').disabled = false
				return null
			})
			const responseJSON = await response.json()

			// document.querySelector('#section_comments').innerHTML =  document.querySelector('#section_comments').innerHTML + ReactDOMServer.renderToString(comment_html);
			document.getElementById('input_comment').value = ''
			// setDataComments(dataComments.concat(responseJSON))
			document.getElementById('input_comment').disabled = false
			setFlag(true)
		}
	}

	const onEnter = (event) => {
		if (event.key === 'Enter') {
			document.getElementById('input_comment').disabled = true
			Callback()
		}
	}

	const getStrMonth3 = (monthStr) => {
		const monthsObj = {
			Jan: '01',
			Feb: '02',
			Mar: '03',
			Apr: '04',
			May: '05',
			Jun: '06',
			Jul: '07',
			Aug: '08',
			Sep: '09',
			Oct: '10',
			Nov: '11',
			Dec: '12',
		}
		return monthsObj[monthStr]
	}

	const deleteComment = async (commentId) => {
		const sessionToken = localStorage.getItem('session')
		const response = await fetch(envVariables.baseUrl + `/comment_pb/delete`, {
			// mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + sessionToken,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
			method: 'POST',
			body: JSON.stringify([
				{
					id: commentId,
				},
			]),
		})
		const responseJSON = await response.json()
		document.getElementById('box_comment_' + commentId).outerHTML = ''
		console.log('Respuesta Comentario Eliminado:::')
		console.log(responseJSON)
	}

	return (
		<div className='popupWrapper OfertaWrapper JumboPopNewVersion TottusPop TottusPopNew'>
			<div className='popup' ref={refCatalogModal}>
				{eliminarShow && (
					<EliminarShow
						seteliminarShow={seteliminarShow}
						data={catalogSelected}
					/>
				)}
				{moverShow && (
					<Mover setmoverShow={setmoverShow} data={catalogSelected} />
				)}

				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<div className='left_side'>
					<h1>
						<img src={bookmark} alt='' />
						{catalogSelected?.nombre_promocion}
					</h1>
					<div className='first_left'>
						<div className='left_box'>
							<h1>Fase</h1>
							<p>{catalogSelected?.fase_promocion}</p>
						</div>
						{ /*
						<div className='left_box'>
							<h1>Estado</h1>
							<p>
								{"Estado"}
							</p>
						</div>
				*/ }
						<div className='left_box right_most_area '>
							
								<>
									<h1>Cadena</h1>
									{/* 
              <img src={null// Activityactive["img"]
              } alt="" />
            */}
									{ /*
									getImgCustomer(
										Activityactive.customer.name_customer.toUpperCase(),
										100
									) */
									}

						<img
										src={getGrocersImg(
											catalogSelected?.nombre_cadena?.replace(/\s/g, '')
										)}
										style={{
											width: '60px',
											height: '100%',
											objectFit: 'cover',
										}}
										alt=''
									/>
								</>
						</div>
					</div>
					<h1 style={{ marginTop: 30 }}>
						<img src={Resumen} alt='' />
						Resumen
					</h1>
					<div
						className='badge_long'
						style={{ gridTemplateColumns: '.7fr .7fr 1fr 1fr .7fr' }}
					>
						<div className='box_inner'>
							<h1>Canal</h1>
							<p>Moderno</p>
						</div>
						<div className='box_inner'>
							<h1>Tipo</h1>
							<p>{catalogSelected?.tipo_promocion}</p>
						</div>
						<div className='box_inner'>
							
								<h1>N° SKU</h1>

							<p>
								{catalogSelected?.sku_count}
							</p>
						</div>
						<div className='box_inner'>
							
								<>
									<h1>Uplift</h1>
									<p>
										{catalogSelected?.uplift}%
									</p>
								</>
						</div>
						<div className='box_inner'>
							<h1>
								{ /* ROI */ }
								Mg. Com. Incr.</h1>
							<p>
									{
									// catalogSelected?.roi
									catalogSelected?.margen_comercial_incremental_porcentual
								}%
							</p>
						</div>

						
					</div>

					<h1 style={{ marginTop: 20 }}>
						<img src={Actividad} alt='' />
						Actividad
					</h1>

					<div className='profile_box_wrapper'>
						<div className='profile_box main_profile_box'>
							<img src={AvatarPop} alt='' />
							<div className='presentation'>
								<input
									type='text'
									placeholder='Escribe un comentario...'
									onKeyPress={(e) => onEnter(e, 1)}
									id='input_comment'
									autoComplete='off'
								/>
							</div>
						</div>
						<div id='section_comments' className='commentsMax'
						style={{height: '200px', maxHeight: '200px', 
						overflowX: "auto"}}> 
							{
							!dataComments
								? ''
								: dataComments.sort((a,b) => b.id_actividad - a.id_actividad).map((EachRow) =>
										EachRow.comentario === 0 ? (
											<div key={EachRow.id_actividad}
												className='profile_box'
												id={'box_comment_' + EachRow.id_actividad}
												style={{ marginBottom: '10px' }}
											>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
															{EachRow.usuario_actividad}
														</h1>
													</div>
													<div className='middle_area'>
														<p>
															{EachRow.actividad}
															
															<span>
															
															</span>
														</p>
													</div>
													<p>
													{EachRow.fase_actividad && EachRow.fase_actividad+" | "} {EachRow.datetime_actividad}
														{
															/* <span
                        onClick={(e) => {
                          // deleteComment(EachRow.id_comment)
                        }}
                        style={{marginRight:"0px"}}
					>Eliminar</span> */}
													</p>
												</div>
											</div>
										) : (
											<div className='profile_box responder' key={EachRow.id_actividad}>
												<img src={AvatarPop} alt='' />
												<div className='presentation'>
													<div className='top_area'>
														<h1>
														{EachRow.usuario_actividad}
														</h1>
														<p>
														{EachRow.fase_actividad && EachRow.fase_actividad+" | "} {EachRow.datetime_actividad}
														</p>
													</div>
													<input
														type='text'
														placeholder={EachRow.actividad}
														value={EachRow.actividad}
													/>
													
												</div>
											</div>
										)
								  )}
						</div>

					</div>
				</div>
				<div className='right_side'>
					<div className='buttons_right_side'>
						<h1>Promoción</h1>
						
								<button
									className='active'
									onClick={() => {
										 window.location.href="/catalogo/"+catalogSelected?.id_promo
										
									}}
								>
									Editar
								</button>

								<button
									className='active'
									onClick={() => {
										window.location.href="/catalogo/"+catalogSelected?.id_promo+"?v=1"
									}}
								>
									Visualizar
								</button>
						
					</div>
					<div className='buttons_right_side'>
						<h1>Fase</h1>
						<button
							className='active'
							onClick={(e) => {
								setmoverShow(!moverShow)
								
							}}
						>
							Mover
						</button>
					</div>
					<div className='buttons_right_side'>
						<h1>Acciones</h1>
						<button
							className='active'
							onClick={(e) => {
								seteliminarShow(true)
							}}
						>
							Eliminar
						</button>
						{ /*
						<button className=''>Duplicar</button>
						*/ }
						{
							// Para activar el boton, poner en className "active"
						}
					</div>
				</div>
			</div>
		</div>
	)
}
